<template>
  <div class='text-center py-16 px-8 max-w-xl mx-auto overflow-y-auto'>
    <check-circle-icon class='w-full mb-4 text-blue-600' size='6x' />
    <div class='font-semibold text-2xl w-full'>{{ registrationFinishTitle }}</div>
    <div class='mt-2 text-gray-500 mb-12'>{{ registrationFinishDescription }}</div>
    <div v-if='isSingleOrder'
      class='bg-gray-100 p-8 mb-12'>
      <div class='flex flex-row'>
        <div class='font-semibold w-1/3 text-left'>{{ orderIdText }}</div>
        <div class='text-gray-500 w-2/3 text-right'>{{hashedOrderId}}</div>
      </div>
      <div class='flex flex-row mb-4'>
        <div class='font-semibold w-1/3 text-left'>{{ orderNameText }}</div>
        <div class='text-gray-500 w-2/3 text-right'>{{ currentOrder.orderName }}</div>
      </div>
      <div class='mt-8'>
        <div class='bg-gray-100 text-gray-900 uppercase font-semibold flex flex-row justify-between items-center'>
          <span>{{ totalAmountText }} ({{orderPriceUnit}}):</span>
          <span>{{singleOrderAmountString}}</span>
        </div>
      </div>
    </div>

    <div v-else
      class='bg-gray-100 p-8 mb-12'>
      <div class='flex flex-row'>
        <div class='font-semibold w-1/3 text-left'>{{ orderIdText }}</div>
        <div class='text-gray-500 w-2/3 text-right'>{{hashedOrderId}}</div>
      </div>
      <table class='w-full text-sm table-fixed mt-8'>
        <thead>
          <tr class='uppercase border-b'>
            <th class='text-left'>Attendee</th>
            <th class='text-right'>Registration Fee</th>
          </tr>
        </thead>
        <tbody class='text-xs lg:text-sm'>
          <tr v-for='(attendee, index) in registeringAttendees'
            :key='`payment-finished-registering-attendee-${index}`'
            class='border-b cursor-pointer'
            @click='openRegistration(attendee, index)'>
            <td class='py-2 text-left'>
              {{attendee.profile.firstName}} {{attendee.profile.lastName}}<br>
              {{attendee.email}}
            </td>
            <td class='py-2 text-right'>{{attendeePaymentString(attendee)}} ({{attendee.registrationProduct.name}} )</td>
          </tr>
        </tbody>
      </table>
      <div class='mt-8'>
        <div class='bg-gray-100 text-gray-900 uppercase font-semibold flex flex-row justify-between items-center'>
          <span>{{ totalAmountText }} ({{orderPriceUnit}}):</span>
          <span>{{multipleOrdersTotalAmountDueString}}</span>
        </div>
      </div>
    </div>
    <button
      @click='goToHome'
      class='border py-4 px-8 text-center text-white text-sm uppercase w-full bg-gray-900 block rounded-md'>
      {{ goToHomeButtonText }}
    </button>
  </div>
</template>

<script>
import { CheckCircleIcon } from '@vue-hero-icons/outline'
import numbro from 'numbro'
import isEmpty from 'lodash/isEmpty'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'IamportRegistrationFinishedMobile',
  components: {
    CheckCircleIcon
  },
  watch: {
    'waitingTimeBeforeClose': {
      handler: function (newVal, oldVal) {
        if (!newVal && newVal !== oldVal) {
          window.close()
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('payments', [
      'currentOrder'
    ]),
    ...mapState('users', [
      'registeringAttendees',
    ]),
    ...mapGetters ([
      'isMobile'
    ]),
    isSingleOrder () {
      return this.$route.query.single_order === 'true'
    },
    isKorean () {
      return this.$route.query.is_korean === 'true'
    },
    hashedOrderId () {
      return this.$route.query.order_id
    },
    totalAmountDue () {
      return this.registeringAttendees.map(attendee => {
        return (attendee.registrationProduct && attendee.registrationProduct.price.amount) ? attendee.registrationProduct.price.amount : 0
      }).reduce((a, b) => a + b, 0)
    },
    multipleOrdersTotalAmountDueString () {
      return numbro(this.totalAmountDue).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
    singleOrderAmountString () {
      let amount = this.currentOrder.price ? this.currentOrder.price.amount : 0
      return `${numbro(amount).format({thousandSeparated: true, mantissa: 0})}`
    },
    orderPriceUnit () {
      return this.currentOrder.price ? this.currentOrder.price.priceUnit : ''
    },
    registrationFinishTitle () {
      return this.isKorean ? '등록이 완료되었습니다!' : 'Successfully Registered Yourself!'
    },
    registrationFinishDescription () {
      return this.isKorean ? '등록 및 결제 내용은 등록완료 메일을 확인해 주시기 바랍니다.' : 'You have successfully registered yourself. Please check your email for the confirmation email.'
    },
    orderIdText () {
      return this.isKorean ? '주문번호' : 'Order Id'
    },
    orderNameText () {
      return this.isKorean ? '주문상품' : 'Order Name'
    },
    totalAmountText () {
      return this.isKorean ? '총 결제 금액' : 'Total Amount Paid'
    },
    goToHomeButtonText () {
      return this.isKorean ? '홈으로 가기' : 'Go Home'
    }
  },
  methods: {
    ...mapMutations('users', [
      'resetRegisteringAttendees',
    ]),
    ...mapActions('payments', [
      'resetCurrentOrder',
    ]),
    attendeePaymentString (attendee) {
      return (attendee.registrationProduct && !isEmpty(attendee.registrationProduct.price)) ? `${attendee.registrationProduct.price.priceUnit} ${attendee.registrationProduct.price.amount}` : ''
    },
    goToHome () {
      this.resetRegisteringAttendees()
      this.$router.push({name: 'Main'})
    },
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.resetCurrentOrder()
    this.$emit('update:layout', 'default-layout')
  },
}
</script>
